
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import { kebabCase } from 'lodash'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmAvatar,
    TmEllipsis,
  },
  props: {
    size: {
      type: String as PropType<'large' | ''>,
      default: '',
    },
    disable: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
    height: {
      type: String,
      default: '40px',
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    avatarUrl: {
      type: String,
    },
    avatarColor: {
      type: String,
    },
    avatarIcon: {
      type: String,
    },
    avatarName: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: null,
    },
    checkboxText: {
      type: String,
      default: 'Required',
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      kebabCase,
    }
  },
})
