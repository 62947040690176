
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import { capitalizeFirstLetter } from '@/services/string'
import { tagsOptions } from '@/definitions/_general/_data/optionsList'
import SharingSelect from '@/components/shared/templates/SharingSelect.vue'

export default defineComponent({
  components: {
    SharingSelect,
    TmModal,
    TmFormLine,
    TmEmoji,
    TmButton,
  },
  props: {
    itemName: {
      type: String,
      required: true,
    },
    originalName: {
      type: String,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
    },
    showSharing: {
      type: Boolean,
    },
    showEmoji: {
      type: Boolean,
    },
  },
  setup(props) {
    const name = ref(`${props.originalName} (1)`)
    const include = ref(false)
    const tags = ref(['payment', 'billing'])
    const tagsList = ref(tagsOptions)

    return {
      name,
      include,
      tags,
      tagsList,
      capitalizeFirstLetter,
    }
  },
})
