import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "tm-dropdown-filter-panel__title"
}
const _hoisted_3 = {
  key: 1,
  class: "truncate font-weight-normal distinct--text"
}
const _hoisted_4 = {
  key: 2,
  class: "ml-auto mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-dropdown-filter-panel", {
      [`tm-dropdown-filter-panel--${_ctx.size}`]: _ctx.size,
      'tm-dropdown-filter-panel--disable': _ctx.disable,
    }]),
    style: _normalizeStyle({
      'height': _ctx.height
    })
  }, [
    (_ctx.draggable)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          name: "drag_indicator",
          size: "xLarge",
          class: "mr-2"
        }))
      : _createCommentVNode("", true),
    (_ctx.avatarUrl || _ctx.avatarIcon || _ctx.avatarName)
      ? (_openBlock(), _createBlock(_component_tm_avatar, {
          key: 1,
          class: "mr-2",
          url: _ctx.avatarUrl,
          color: _ctx.avatarColor,
          name: _ctx.avatarName,
          "custom-size": "32px",
          "placeholder-custom-size": "16px"
        }, null, 8, ["url", "color", "name"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_tm_ellipsis, { inline: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.text), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.modelValue !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_tm_field, {
            "model-value": _ctx.modelValue,
            type: "checkbox",
            disable: _ctx.disable,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.checkboxText), 1)
            ]),
            _: 1
          }, 8, ["model-value", "disable"])
        ]))
      : _createCommentVNode("", true)
  ], 6))
}