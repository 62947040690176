
import { defineComponent, reactive, ref } from 'vue'
import TmDropdownFilter from '@/components/shared/dropdowns/TmDropdownFilter.vue'
import TmDropdownFilterPanel from '@/components/shared/dropdowns/TmDropdownFilterPanel.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmDropdownFilterPanel,
    TmDropdownFilter,
    TmAvatar,
  },
  setup() {
    const state = reactive({
      option: 'all',
      specificUsersSelected: [],
      specificTeamsSelected: [],
    })

    const specificUsers = ref([
      {
        uid: 1,
        avatar: 'https://randomuser.me/api/portraits/women/43.jpg',
        fullName: 'Marcus Greene',
        avatarColor: 'orange',
        email: 'markus.greene@textmagic.com',
      },
      {
        uid: 2,
        avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
        fullName: 'Mark Brown',
        avatarColor: 'orange',
        email: 'markus.greene@textmagic.com',
      },
      {
        uid: 3,
        avatar: 'https://randomuser.me/api/portraits/women/45.jpg',
        fullName: 'Paul Lengi',
        avatarColor: 'orange',
        email: 'markus.greene@textmagic.com',
      },
      {
        uid: 4,
        avatar: 'https://randomuser.me/api/portraits/women/46.jpg',
        fullName: 'Stella Nie',
        avatarColor: 'orange',
        email: 'markus.greene@textmagic.com',
      },
    ])

    const specificTeams = ref([
      {
        uid: 1,
        title: 'Status',
        description: '16 users',
        color: 'red',
      },
      {
        uid: 2,
        title: 'Support',
        description: '32 users',
        color: 'purple',
      },
      {
        uid: 3,
        title: 'Devs',
        description: '12 users',
        color: 'blue',
      },
    ])

    return {
      state,
      specificTeams,
      specificUsers,
    }
  },
})
